import React from 'react'

const HiveIcon = ({ height = 16, style }) => {
    return (
        <svg height={height} style={style} viewBox="0 0 1263 1158" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M902.835 653.433C903.786 653.433 904.721 653.7 905.544 654.205C906.368 654.711 907.052 655.437 907.527 656.312C908.002 657.187 908.251 658.179 908.25 659.189C908.249 660.198 907.997 661.19 907.52 662.064L639.146 1154.58C638.671 1155.45 637.987 1156.18 637.165 1156.68C636.343 1157.18 635.41 1157.45 634.461 1157.45H470.437C469.485 1157.45 468.551 1157.18 467.727 1156.68C466.904 1156.17 466.22 1155.44 465.745 1154.57C465.27 1153.69 465.021 1152.7 465.022 1151.69C465.023 1150.68 465.275 1149.69 465.751 1148.82L734.125 656.301C734.601 655.429 735.284 654.705 736.107 654.202C736.929 653.698 737.862 653.433 738.811 653.433H902.835ZM743.275 512.265C742.326 512.263 741.393 511.997 740.57 511.493C739.748 510.988 739.065 510.263 738.59 509.39L465.72 8.63793C465.242 7.7618 464.99 6.7672 464.99 5.75474C464.991 4.74227 465.243 3.74783 465.721 2.87201C466.2 1.99618 466.888 1.27002 467.716 0.766966C468.544 0.263913 469.483 0.00180851 470.437 0.00716043H634.492C635.442 0.00771416 636.374 0.272944 637.197 0.776286C638.019 1.27963 638.702 2.00341 639.178 2.87515L912.048 503.634C912.525 504.508 912.776 505.499 912.778 506.509C912.779 507.518 912.529 508.51 912.054 509.385C911.579 510.26 910.896 510.987 910.072 511.492C909.248 511.998 908.314 512.264 907.363 512.265H743.275Z" fill="#dc3545" />
            <path d="M775.714 8.63793C775.237 7.76327 774.985 6.77053 774.985 5.75978C774.984 4.74903 775.235 3.75599 775.711 2.88076C776.188 2.00553 776.873 1.27905 777.698 0.774549C778.524 0.270048 779.46 0.00535737 780.412 0.00715887H944.701C945.654 0.00622273 946.591 0.271365 947.416 0.775958C948.242 1.28055 948.928 2.00683 949.406 2.88185L1262.2 575.843C1262.68 576.719 1262.93 577.711 1262.93 578.721C1262.93 579.731 1262.68 580.724 1262.2 581.599L949.406 1154.57C948.926 1155.45 948.24 1156.17 947.414 1156.67C946.589 1157.18 945.653 1157.44 944.701 1157.44H780.412C779.461 1157.44 778.526 1157.18 777.701 1156.67C776.877 1156.17 776.192 1155.44 775.716 1154.57C775.239 1153.7 774.989 1152.7 774.988 1151.69C774.988 1150.68 775.238 1149.69 775.714 1148.82L1086.94 578.724L775.714 8.63793ZM642.196 575.823C642.682 576.698 642.938 577.695 642.938 578.711C642.938 579.727 642.682 580.724 642.196 581.599L327.927 1154.57C327.445 1155.45 326.754 1156.18 325.923 1156.69C325.091 1157.2 324.15 1157.46 323.191 1157.46C322.233 1157.46 321.291 1157.2 320.46 1156.69C319.629 1156.18 318.938 1155.45 318.455 1154.57L0.738324 581.646C0.254865 580.771 0 579.775 0 578.761C0 577.747 0.254865 576.751 0.738324 575.877L315.007 2.88185C315.491 2.00468 316.183 1.27715 317.014 0.771581C317.845 0.266008 318.786 0 319.743 0C320.701 0 321.642 0.266008 322.473 0.771581C323.304 1.27715 323.996 2.00468 324.479 2.88185L642.196 575.823Z" fill="#dc3545" />
        </svg>
    )
}

export default HiveIcon
