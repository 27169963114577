import React from "react";
import { withStyles } from "@material-ui/styles";
import { NavLink } from "react-router-dom";
import { Icon } from "@material-ui/core";
import TouchRipple from "@material-ui/core/ButtonBase";

const styles = theme => ({
    expandIcon: {
        transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
        transform: "rotate(90deg)"
    },
    collapseIcon: {
        transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
        transform: "rotate(0deg)"
    }
});


const VerticalNav = (props) => {
    const { navigation } = props

    const renderLevels = data => {
        return data.map((item, index) => {
            return (
                <NavLink key={index} to={item.path} className="nav-item">
                    <TouchRipple key={item.name} name="child" className="w-100">
                        {(() => {
                            if (item.icon) {
                                return (
                                    <Icon className="item-icon text-middle">{item.icon}</Icon>
                                );
                            } else {
                                return (
                                    <span className="item-icon icon-text">{item.iconText}</span>
                                );
                            }
                        })()}
                        <span className="text-middle pl-20 item-text">{item.name}</span>
                        <div className="mx-auto"></div>
                        {item.badge && (
                            <div className={`badge bg-${item.badge.color}`}>
                                {item.badge.value}
                            </div>
                        )}
                    </TouchRipple>
                </NavLink>
            )
        })
    }

    return (
        <div className="navigation">
            {renderLevels(navigation)}
        </div>
    )
}

export default withStyles(styles)(VerticalNav)